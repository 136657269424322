import { Configuration } from '../../constant/configuration';

const configuration = new Configuration();

export function addParamToUrl(param: string, value: string): void {
    const url = new URL(window.location.href.replace('o/', ''));
    url.searchParams.delete(param);
    if (value !== undefined && value !== null) {
        url.searchParams.set(param, value);
    }
    const newUrl = url.origin + '/o' + url.pathname + url.search;
    window.history.pushState(null, null, newUrl);
}

export function isGlossaryUrl(langPath: string, glossaryPath: string): boolean {
    const langPathLowerCase = langPath?.toLowerCase();
    const glossaryPathLowercase = glossaryPath?.toLowerCase();
    return (
        configuration.languagesArray.some(lang => lang.toLowerCase() === langPathLowerCase) &&
        configuration.glossaryTranslationsArray.some(glossary => glossary.toLowerCase() === glossaryPathLowercase)
    );
}
