import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Configuration } from '../constant/configuration';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

type ITitleMetaTag = { title: string };
type ITitleTranslationMetaTag = {
    titleTranslationKey: string;
    titleInterpolateParams?: { [key: string]: string };
};

type IMetaTag = (ITitleMetaTag | ITitleTranslationMetaTag) & {
    descriptionTranslationKey?: string;
    descriptionInterpolateParams?: { [key: string]: string };
    description?: string;
    imageUrl?: string;
};

@Injectable({ providedIn: 'root' })
export class SeoTagsService {
    lastUrlDefinedSeo: IMetaTag & { url: string };
    isSEODefinedByComponent = false;
    constructor(
        private translateService: TranslateService,
        private configuration: Configuration,
        private title: Title,
        private meta: Meta,
        private router: Router
    ) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                const lastUrl = this.lastUrlDefinedSeo?.url.split('?')[0];
                const newUrlToNavigate = event.url.split('?')[0];

                if (this.lastUrlDefinedSeo && !newUrlToNavigate.startsWith(lastUrl)) {
                    this.isSEODefinedByComponent = false;
                    this.lastUrlDefinedSeo = null;
                }
            }

            if (event instanceof NavigationEnd) {
                if (!this.isSEODefinedByComponent) {
                    this.defineDefaultMetaTags();
                }
            }
        });
    }

    initialize(): void {
        this.defineDefaultMetaTags();

        this.translateService.onLangChange.subscribe(() => {
            if (this.lastUrlDefinedSeo) {
                this.updateSEOTags(this.lastUrlDefinedSeo);
            }
        });
    }

    defineDefaultMetaTags(): void {
        this.updateSEOTags(
            {
                titleTranslationKey: 'global.title',
                descriptionTranslationKey: 'global.description'
            },
            false
        );
    }

    updateSEOTags(payload: IMetaTag, isSEODefinedByComponent = true): void {
        this.isSEODefinedByComponent = isSEODefinedByComponent;
        this.lastUrlDefinedSeo = { ...payload, url: this.router.url };

        const title = this.generateTitleFromMetaTag(payload);
        const description = this.generateDescriptionFromMetaTag(payload);

        this.title.setTitle(title);
        this.meta.updateTag({ property: 'og:title', content: title });
        this.meta.updateTag({ name: 'description', content: description });
        this.meta.updateTag({ property: 'og:description ', content: description });

        if (payload.imageUrl) {
            this.meta.updateTag({ property: 'og:image', content: payload.imageUrl });
        } else {
            this.meta.updateTag({ property: 'og:image', content: 'images/logo/lexnow_big.png' });
        }
    }

    generateTitleFromMetaTag(payload: IMetaTag): string {
        if ((payload as ITitleMetaTag).title) {
            return `${this.configuration.appName} | ${(payload as ITitleMetaTag).title}`;
        } else {
            const titleData = payload as ITitleTranslationMetaTag;
            return `${this.configuration.appName} |
              ${
                  this.translateService.instant(
                      titleData.titleTranslationKey,
                      titleData.titleInterpolateParams || {}
                  ) as string
              }`;
        }
    }

    generateDescriptionFromMetaTag(payload: IMetaTag): string {
        let description = '';
        if (payload.description) {
            description = payload.description;
        } else if (payload.descriptionTranslationKey) {
            description = this.translateService.instant(
                payload.descriptionTranslationKey,
                payload.descriptionInterpolateParams || {}
            ) as string;
        }

        return description;
    }
}
