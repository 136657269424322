import { Component } from '@angular/core';
import { MixpanelService } from './services/mixpanel.service';
import { MessageBus } from './messaging/MessageBus';
import { Configuration } from './constant/configuration';
import { AuthenticateService } from './services/authenticate.service';
import { NotificationMessageService } from './services/notification_message.service';
import { SeoTagsService } from './services/seo-tags.service';
import { environment } from '../environments/environment';
import { PreviousRouteService } from './shared/service/previous-route.service';
import { Router } from '@angular/router';
import { LanguageService } from './shared/service/language.service';
import { userAgentIsGoogleBot } from './shared/utils/browser';

@Component({
    selector: 'app-my-app',
    templateUrl: './app.component.html',
    styles: ['.active { font-weight: bold } a + a { margin-left: .5em; }']
})
export class AppComponent {
    constructor(
        mixpanelService: MixpanelService,
        messageBus: MessageBus,
        private configuration: Configuration,
        private authenticateService: AuthenticateService,
        private notificationMessageService: NotificationMessageService,
        private router: Router,
        private languageService: LanguageService,
        previousRouteService: PreviousRouteService,
        seoTagsService: SeoTagsService
    ) {
        previousRouteService.initialize();
        seoTagsService.initialize();
        mixpanelService.initialize();
        if (!userAgentIsGoogleBot()) {
            this.loadGRecaptcha();
        }
        this.loadGoogleCloudAI();
        this.removeHashTagFromOldLocationStrategyAndRedirectIfNecessary();

        messageBus.channel(configuration.messageBusChannels.SESSION_CHANNEL).subscribe(message => {
            if (message.message === 'lostSession' && !this.router.url.startsWith('/public')) {
                this.onLostSession(message.data);
            }
        });
    }

    onLostSession(data): void {
        const previousCredentials = this.authenticateService.getPreviousCredentials();
        const authenticationType = this.authenticateService.getPreviousAuthenticationType();
        // We manage here the navigation rules to keep the last url called and navigate to them after the logIn page
        if (data.url !== '/' && !this.authenticateService.isAutoLogonUser()) {
            if (
                previousCredentials &&
                Date.now() - previousCredentials.timestamp < this.configuration.maxTimeoutReconnectWithoutLogin
            ) {
                this.notificationMessageService.init(
                    'global.errors.session-expired',
                    'global.errors.disconnected-reconnect'
                );
                this.notificationMessageService.addRightButton({
                    label: 'global.forms.disconnect',
                    icon: 'login-logout-icon',
                    callback: () => {
                        void this.authenticateService.logout();
                        void this.router.navigate(['login']);
                    }
                });
                this.notificationMessageService.addLeftButton({
                    label: 'global.forms.reconnect',
                    icon: 'login-reconnect-icon',
                    callback: () => {
                        void this.authenticateService.login(previousCredentials, authenticationType).then(() => {
                            this.reload();
                        });
                    }
                });
            } else {
                this.notificationMessageService.init('global.errors.session-expired', 'global.errors.disconnected');
                this.notificationMessageService.addLeftButton({
                    label: 'global.forms.ok',
                    callback: () => {
                        void this.authenticateService.logout().then(() => {
                            void this.router.navigate(['login']);
                        });
                    }
                });
            }
            this.notificationMessageService.show();
        } else {
            if (this.authenticateService.isAutoLogonUser()) {
                // This is a public(autologon) profil. We redirect immediatly where the connection is lost.
                void this.authenticateService.login(previousCredentials, authenticationType).then(() => {
                    this.reload();
                });
            }
        }
    }

    reload(): void {
        if (this.router.navigated) {
            this.router.navigated = false;
            void this.router.navigate([this.router.url]);
        } else {
            window.location.reload();
        }
    }

    loadGRecaptcha(): void {
        this.loadScript(`https://www.google.com/recaptcha/enterprise.js?render=${environment.recaptcha.siteKey}`);
    }

    loadGoogleCloudAI(): void {
        const currentLanguage = this.languageService.selectedLanguageData.twoLettersLanguage;
        this.loadScript(`https://cloud.google.com/ai/gen-app-builder/client?hl=${currentLanguage}`);
    }

    loadScript(scriptUrl: string): void {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = scriptUrl;

        document.head.appendChild(script);
    }

    removeHashTagFromOldLocationStrategyAndRedirectIfNecessary(): void {
        // before to switch to PathLocationStrategy we used HashLocationStrategy
        // For back compatibility we redirect url of forms localhost/#/o/... to localhost/o/...
        const origin = location.origin;
        if (location.href.startsWith(`${origin}/#/`)) {
            const newUrl = location.href.replace(`${origin}/#`, '');
            void this.router.navigateByUrl(newUrl);
        }
    }
}
